import React from "react";
import { cx } from "class-variance-authority";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

type Side = "top" | "right" | "bottom" | "left";

function TooltipContent({ side, children, wrap = false }: { side: Side; children: React.ReactNode; wrap: boolean }) {
  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        sideOffset={5}
        className={cx("bg-gray-80 z-50 rounded-lg px-2 py-1 text-sm text-white", { "max-w-md": wrap })}
        onPointerDownOutside={(e) => e.preventDefault()}
        side={side}
      >
        {children}
        <TooltipPrimitive.Arrow className="fill-gray-80" />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  );
}

const Provider = TooltipPrimitive.Provider;
const Root = TooltipPrimitive.Root;
const Trigger = TooltipPrimitive.Trigger;
const Content = TooltipContent;

type TooltipProps = {
  content?: React.ReactNode;
  contentFn?: () => React.ReactNode;
  side: Side;
  wrap?: boolean;
  children: React.ReactNode;
  display?: boolean;
};

export const Tooltip = ({ content, side, children, contentFn, display = true, wrap = false }: TooltipProps) => {
  const renderContent = contentFn ? contentFn() : content || "";

  if (!display) {
    return <>{children}</>;
  }
  return (
    <Provider delayDuration={500}>
      <Root>
        <Trigger asChild>{children}</Trigger>
        <Content side={side} wrap={wrap}>
          {renderContent}
        </Content>
      </Root>
    </Provider>
  );
};
